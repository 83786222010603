import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import config from '@/config';
import { Storage } from '@/classes/Storage';

Vue.use(Vuetify);

import en from 'vuetify/es5/locale/en';
import de from 'vuetify/es5/locale/de';
import fr from 'vuetify/es5/locale/fr';
import it from 'vuetify/es5/locale/it';

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#64b43c',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  lang: {
    locales: { en, de, fr, it },
    current: Storage.get('locale') || config.get('app.i18nLocale', 'de'),
  },
});
