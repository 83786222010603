export default {
  namespaced: true,

  state: {
    snack: null,
  },

  actions: {
    setSnack({ commit }, snack) {
      commit('setSnack', snack);
    },
  },

  mutations: {
    setSnack: (state, snack) => {
      state.snack = snack;
    },
  },
};
