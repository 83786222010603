import Errors from '../classes/Errors';

export default {
  data: () => ({
    errors: new Errors(),
  }),

  methods: {
    handleErrorsByResponse({ status, errors }) {
      if (status === 422) {
        this.errors.record(errors);
      }
    },

    clearErrorByName(fieldName, prefix) {
      if (!fieldName) {
        return;
      }

      this.errors.clear(prefix ? `${prefix}.${fieldName}` : fieldName);
    },

    clearErrorByEvent(event, prefix) {
      if (!event || !event.target) {
        return;
      }

      this.clearErrorByName(event.target.name, prefix);
    },
  },
};
