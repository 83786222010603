import qs from 'qs';

import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';

export class BaseService {
  static get entity() {
    throw new Error('entity getter not defined');
  }

  static request(meta = { auth: true }) {
    return new Http(meta);
  }

  static responseWrapper(...rest) {
    return new ResponseWrapper(...rest);
  }

  static errorWrapper(...rest) {
    return new ErrorWrapper(...rest);
  }

  static querystring(obj) {
    return qs.stringify(obj, {
      encode: false,
    });
  }

  static async getList(parameters = {}) {
    const params = { ...parameters };

    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}`,
        { params }
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getById(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}/${id}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async create(data = {}) {
    try {
      const response = await this.request({ auth: true }).post(
        `${this.entity}`,
        data
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async update(id, data = {}) {
    try {
      const response = await this.request({ auth: true }).patch(
        `${this.entity}/${id}`,
        data
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async remove(id) {
    try {
      const response = await this.request({ auth: true }).delete(
        `${this.entity}/${id}`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
