import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class PersonsService extends BaseService {
  static get entity() {
    return 'persons';
  }

  static async getStatuses() {
    try {
      const response = await this.request({ auth: true, base_url: true }).get(
        `${this.entity}/statuses`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getMaritalStatuses() {
    try {
      const response = await this.request({ auth: true, base_url: true }).get(
        `${this.entity}/marital-statuses`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getGenders() {
    try {
      const response = await this.request({ auth: true, base_url: true }).get(
        `${this.entity}/genders`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getSalutations() {
    try {
      const response = await this.request({ auth: true, base_url: true }).get(
        `${this.entity}/salutations`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getFilteredBuildingComplexes(params = {}) {
    try {
      const response = await this.request({ auth: true }).get(
        `filters/${this.entity}/building-complexes`,
        params
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getById(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}/${id}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getSharedDocumentsDirs({ personId }) {
    try {
      const response = await this.request().get(
        `${this.entity}/${personId}/shared-documents/dirs`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async getSharedDocumentsByDir({ personId, dirId }) {
    try {
      const response = await this.request().get(
        `${this.entity}/${personId}/shared-documents/dirs/${dirId}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
