import { cacheAction } from 'vuex-cache';
import { Storage } from '@/classes/Storage';
import { LanguagesService } from '@/services/languages.service';

export default {
  updateLanguageApp: cacheAction(({ cache, dispatch }, { locale }) => {
    return LanguagesService.updateLanguageApp({
      user_app_locale: locale,
    }).then((response) => {
      cache.clear();
      Storage.set('locale', locale);
      dispatch('setLang', { lang: locale });

      return response;
    });
  }),

  setLang({ commit }, { lang }) {
    commit('setLang', lang);
  },

  retrieveLanguages: () => {
    return LanguagesService.getList();
  },
};
