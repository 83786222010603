import axios from 'axios';

import config from '@/config';
import { Storage } from '@/classes/Storage';
import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';

const API_URL = config.get('app.coreApi');

let BEARER = `Bearer ${Storage.get('api_token', '')}`;

export class AuthService {
  static async makeLogin({ login, password }) {
    try {
      const response = await axios.post(
        `${API_URL}/auth/user/immoportal-login`,
        {
          login,
          password,
        }
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async makeLogout() {
    try {
      const response = await new Http().post(`${API_URL}/auth/user/logout`);

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static getBearer() {
    return BEARER;
  }

  static setBearer(apiToken) {
    BEARER = `Bearer ${apiToken}`;
  }
}
