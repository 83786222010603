import { env } from '@/utils';

export default {
  environment: env('NODE_ENV', 'development'),
  coreApi: env('VUE_APP_CORE_API', 'http://localhost:8081'),
  urlInfoscreen: env(
    'VUE_APP_URL_INFOSCREEN',
    'https://infoscreen.bonacasa.ch/'
  ),
  coreApiPrefix: env('VUE_APP_CORE_API_PREFIX', 'immoportal'),
  i18nLocale: env('VUE_APP_I18N_LOCALE', 'de'),
  i18nFallbackLocale: env('VUE_APP_I18N_FALLBACK_LOCALE', 'en'),
  favicon: env('VUE_APP_FAVICON', 'favicon.ico'),
  isProduction: env('VUE_APP_IS_PRODUCTION', true),
};
