import { PersonsService } from '../../../services/persons.service';

export default {
  getStatuses() {
    return PersonsService.getStatuses();
  },

  getPersons(_, { params } = {}) {
    return PersonsService.getList(params);
  },

  getPerson(_, { personId }) {
    return PersonsService.getById(personId);
  },

  getFilteredBuildingComplexes(_, { params } = {}) {
    return PersonsService.getFilteredBuildingComplexes(params);
  },
};
