import i18n from '@/i18n.js';
import { DateTime, Interval } from 'luxon';
import { isValidDate as isValidDateFunction } from '@/helpers/isValidDate';

let templateEmail = /.+@.+\..+/;
let templateEmoji =
  /\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]/g;

export const notEmoji = (v) =>
  !v || !v.match(templateEmoji) || i18n.t('MESSAGE.THIS_FIELD_MUST_BE_VALID');

export const isEmail = (v) =>
  !v || templateEmail.test(v) || i18n.t('MESSAGE.THIS_FIELD_MUST_BE_VALID');

export const isInteger = (v) =>
  (!isNaN(v) && Number(v) % 1 === 0) || i18n.t('MESSAGE.INVALID_INTEGER');

export const isFloat = (v) =>
  (!isNaN(v) && Number(v) % 1 !== 0) || i18n.t('MESSAGE.INVALID_FLOAT');

export const isNumeric = (n) =>
  (!isNaN(parseFloat(n)) && isFinite(n)) || i18n.t('MESSAGE.INVALID_NUMERIC');

export const maxLengthNumber = (count) => (v) =>
  !v ||
  Math.floor(v).toString().length <= count ||
  i18n.t('MESSAGE.INVALID_LENGTH');

export const maxLengthArray = (count) => (v) =>
  !v || v.length <= count || i18n.t('MESSAGE.INVALID_LENGTH_ARRAY', { count });

export const maxLengthUploadArray = (count) => (v) =>
  !v || v.length <= count || i18n.t('MESSAGE.INVALID_LENGTH_UPLOAD_ARRAY');

export const maxLengthMaskedPhone = (count) => (v) =>
  !v ||
  v.replace(/[^0-9]/gi, '').length <= count ||
  i18n.t('MESSAGE.INVALID_LENGTH');

export const notEmpty = (v) => !!v || i18n.t('MESSAGE.THIS_FIELD_IS_REQUIRED');

export const notEmptyArray = (v) =>
  !!v.length || i18n.t('MESSAGE.THIS_FIELD_IS_REQUIRED');

export const notEmptyIf = (condition) => (v) =>
  (!!v && !!condition) || i18n.t('MESSAGE.THIS_FIELD_IS_REQUIRED');

export const maxLength = (count) => (v) =>
  !v || v.toString().length <= count || i18n.t('MESSAGE.INVALID_LENGTH');

export const minLength = (count) => (v) =>
  !v || v.toString().length >= count || i18n.t('MESSAGE.INVALID_LENGTH');

export const positiveNumber = (v) =>
  !v ||
  !v.toString().includes('-') ||
  i18n.t('MESSAGE.THIS_FIELD_MUST_BE_VALID');

export const dateBetween = (
  end = '1900-01-01',
  start = null,
  format = 'dd.MM.yyyy'
) => {
  const startDate = start ? DateTime.fromISO(start) : DateTime.now();
  const endDate = DateTime.fromISO(end);
  const interval = Interval.fromDateTimes(endDate, startDate);

  return (v) => {
    if (!v) {
      return true;
    }

    const value = DateTime.fromFormat(v, format);

    if (!value.isValid || !interval.contains(value)) {
      return i18n.t('MESSAGE.INVALID_DATE');
    }

    return true;
  };
};

export const isValidDate = (format = 'dd.MM.yyyy') => {
  return isValidDateFunction(format, i18n.t('MESSAGE.INVALID_DATE'));
};

export const requiredSelectArray = [
  (v) => !!v.length || i18n.t('MESSAGE.THIS_FIELD_IS_REQUIRED'),
];
