import LayoutChildren from '@/views/LayoutChildren.vue';

const routes = [
  {
    path: '/persons',
    component: LayoutChildren,

    children: [
      {
        path: '',
        name: 'persons',
        component: () => import('../views/PersonsPage.vue'),
      },
      {
        path: ':id',
        name: 'persons.show',
        component: () => import('../views/PersonShowPage.vue'),
        props: (route) => ({
          id: Number(route.params.id),
        }),
      },
    ],
  },
];

export default routes;
