import { InhabitantChangesService } from '@/services/inhabitant-changes.service';
import { PersonsService } from '@/services/persons.service';

export default {
  setPageOptions({ commit }, { pageOptions }) {
    commit('pageOptions', pageOptions);
  },

  getInhabitantChanges(_, { params } = {}) {
    return InhabitantChangesService.getList(params);
  },

  getSalutations: () => {
    return PersonsService.getSalutations();
  },

  getStatuses: () => {
    return InhabitantChangesService.getStatuses();
  },

  getGenders: () => {
    return PersonsService.getGenders();
  },

  getMaritalStatuses: () => {
    return PersonsService.getMaritalStatuses();
  },
};
