<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <span>{{ lang }}</span>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="language in languages"
        :key="language.locale"
        @click="changeLocale(language.locale)"
      >
        <v-list-item-title>{{ language.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data: () => ({
    languages: [
      {
        locale: 'en',
        title: 'English',
      },
      {
        locale: 'de',
        title: 'Deutsch',
      },
    ],
  }),

  computed: {
    ...mapState('localization', ['lang']),
  },

  methods: {
    ...mapActions('localization', ['updateLanguageApp']),

    changeLocale(locale) {
      this.$vuetify.lang.current = locale;
      this.$i18n.locale = locale;
      this.updateLanguageApp({ locale });
    },
  },
};
</script>
