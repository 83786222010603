import auth from './auth';
import localization from './localization';
import snackbar from './snackbar';
import person from '@/modules/persons/store';
import notification from '@/modules/notifications/store';
import building_complex from '@/modules/building_complexes/store';
import inhabitant_change from '@/modules/inhabitant_changes/store';
import post from '@/modules/posts/store';

export default {
  auth,
  localization,
  snackbar,
  person,
  notification,
  building_complex,
  inhabitant_change,
  post,
};
