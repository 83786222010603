import * as flat from 'flat';
import { get, has, unset, isEmpty, extend } from 'lodash';

class Errors {
  /**
   * Create a new Errors instance.
   */
  constructor() {
    this._errors = {};
  }

  /**
   * Determine if an errors exists for the given field.
   *
   * @param {string} field
   */
  has(field) {
    return has(this._errors, field);
  }

  /**
   * Determine if we have any errors.
   *
   * @returns {boolean}
   */
  any() {
    return Object.keys(this._errors).length > 0;
  }

  /**
   * Retrieve the error message for a field.
   *
   * @param {string} field
   * @param {*} fallback
   */
  get(field, fallback = undefined) {
    if (!field || !this.any()) {
      return fallback;
    }

    if (this.has(field)) {
      return get(this._errors, field, fallback);
    }

    return fallback;
  }

  /**
   * Get all errors
   *
   * @param fallback
   * @returns {*}
   */
  getAll(fallback = {}) {
    return this.any() ? this._errors : fallback;
  }

  /**
   * Record the new errors.
   *
   * @param {object} errors
   */
  record(errors) {
    for (let field in errors) {
      this[field] = errors[field];
    }

    this._errors = flat.unflatten(errors);
  }

  /**
   * Clear one or all error fields.
   *
   * @param {string|null} field
   */
  clear(field) {
    if (field) {
      delete this[field];
      unset(this._errors, field);

      // Clear empty [] or {}
      if (field.indexOf('.') !== -1) {
        const parent = field.split('.').slice(0, -1).join('.');

        if (isEmpty(this.get(parent))) {
          unset(this._errors, parent);
        }
      }

      this._errors = extend({}, this._errors);

      return;
    }

    this._errors = {};
  }
}

export { Errors as default };
