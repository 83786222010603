import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class BuildingComplexesService extends BaseService {
  static get entity() {
    return 'building-complexes';
  }

  static async getById(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}/${id}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFilteredBusinessCustomers(parameters) {
    const params = { ...parameters };

    try {
      const response = await this.request({ auth: true }).get(
        `/filters/${this.entity}/business-customers`,
        { params }
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFilteredManagementCompanies(parameters) {
    const params = { ...parameters };

    try {
      const response = await this.request({ auth: true }).get(
        `/filters/${this.entity}/management-companies`,
        { params }
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFilteredConcierges(parameters) {
    const params = { ...parameters };

    try {
      const response = await this.request({ auth: true }).get(
        `/filters/${this.entity}/concierges`,
        { params }
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getTypes() {
    try {
      const response = await this.request({ auth: true }).get(
        `/filters/${this.entity}/types`
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getStatuses() {
    try {
      const response = await this.request({ auth: true }).get(
        `/filters/${this.entity}/statuses`
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getSimplifiedList() {
    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}-simplified-list`
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getBuildingComplexBuildings(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}/${id}/buildings`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getBuildingApartments(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `buildings/${id}/apartments`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
