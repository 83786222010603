import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import config from './plugins/config';
import i18n from './i18n';
import CKEditor from '@ckeditor/ckeditor5-vue';

Vue.use(CKEditor);

Vue.config.productionTip = false;

import './mixins';

Vue.use(config);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
