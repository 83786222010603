import { Storage } from '@/classes/Storage';

export default {
  setToken: ({ commit }, { token }) => {
    Storage.set('api_token', token);
    commit('setToken', token);
  },

  removeToken: ({ commit }) => {
    Storage.remove('api_token');
    commit('removeToken');
  },

  setUser: ({ commit }, { user }) => {
    Storage.set('user', user);
    commit('setUser', user);
  },

  removeUser: ({ commit }) => {
    Storage.remove('locale');
    Storage.remove('user');
    commit('removeUser');
  },
};
