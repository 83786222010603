import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class LanguagesService extends BaseService {
  static get entity() {
    return 'languages';
  }

  static async getList() {
    try {
      const response = await this.request({ auth: true, base_url: true }).get(
        this.entity
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateLanguageApp(data = {}) {
    try {
      const response = await this.request({ auth: true, base_url: true }).patch(
        `app-language-id`,
        data
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
