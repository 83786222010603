export default {
  setToken: (state, token) => {
    state.token = token;
  },

  removeToken: (state) => {
    state.token = null;
  },

  setUser: (state, user) => {
    state.user = user;
  },

  removeUser: (state) => {
    state.user = null;
  },
};
