import LayoutChildren from '@/views/LayoutChildren.vue';

const routes = [
  {
    path: '/inhabitant_changes',
    component: LayoutChildren,

    children: [
      {
        path: '',
        name: 'inhabitant_changes',
        component: () => import('../views/InhabitantChangesPage.vue'),
      },
    ],
  },
];

export default routes;
