import LayoutChildren from '@/views/LayoutChildren.vue';

const routes = [
  {
    path: '/posts',
    component: LayoutChildren,

    children: [
      {
        path: '',
        name: 'posts',
        component: () => import('../views/PostsPage.vue'),
      },
      {
        path: 'create',
        name: 'posts.create',
        component: () => import('../views/PostCreatePage.vue'),
      },
      {
        path: ':id',
        name: 'posts.edit',
        component: () => import('../views/PostEditPage.vue'),
        props: (route) => ({
          id: Number(route.params.id),
        }),
      },
    ],
  },
];

export default routes;
