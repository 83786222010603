import axios from 'axios';

import config from '@/config';
import { AuthService } from '@/services/auth.service';

const API_URL = config.get('app.coreApi');
const API_URL_PREFIX = config.get('app.coreApiPrefix');
export const CancelToken = axios.CancelToken;

export class Http {
  constructor(meta) {
    this.isAuth = meta && meta.auth ? meta.auth : true;
    this.instance = axios.create({
      baseURL: meta && meta.base_url ? API_URL : `${API_URL}/${API_URL_PREFIX}`,
    });

    return this.init();
  }

  init() {
    if (this.isAuth) {
      this.instance.interceptors.request.use(
        (request) => {
          request.headers.authorization = AuthService.getBearer();

          return request;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }

    this.instance.interceptors.request.use((request) => {
      if (request.data instanceof FormData) {
        if (request.method === 'patch') {
          request.method = 'post';
          request.data.set('_method', 'PATCH');
        }

        Object.assign(request.headers, {
          'Content-Type': 'multipart/form-data',
        });
      }

      return request;
    });

    return this.instance;
  }
}
