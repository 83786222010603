import $store from '../store';
import config from '@/config';
import { Storage } from '@/classes/Storage';

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {
  const hasToken = $store.getters['auth/HAS_TOKEN'];
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth);

  if (isAuthRoute && hasToken) {
    return next();
  }

  if (isAuthRoute) {
    return next({ name: 'login' });
  }

  next();
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title);

  window.document.title = pageTitle
    ? pageTitle.meta.title
    : document.location.hostname;

  next();
}

export function setLocale(to, from, next) {
  if (!Storage.get('locale')) {
    Storage.set('locale', config.get('app.i18nLocale'));
  }

  next();
}
