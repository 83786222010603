export class Storage {
  /**
   * Get item
   *
   * @param key
   * @param fallback
   * @returns {null|any}
   */
  static get(key, fallback = null) {
    let value = localStorage.getItem(key);

    return value === null ? fallback : JSON.parse(value);
  }

  /**
   * Set item
   *
   * @param key
   * @param value
   */
  static set(key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Remove item
   *
   * @param key
   * @returns {boolean}
   */
  static remove(key) {
    if (this.isset(key)) {
      localStorage.removeItem(key);

      return true;
    }

    return false;
  }

  /**
   * Clear all
   */
  static clear() {
    return localStorage.clear();
  }

  /**
   * Check existing key
   *
   * @param key
   * @returns {boolean}
   */
  static isset(key) {
    return this.get(key) !== null;
  }
}
