import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from '@/services/util';

export class NotificationsService extends BaseService {
  static get entity() {
    return 'notifications';
  }

  static async getById(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}/${id}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getManagementCompanies() {
    try {
      const response = await this.request({ auth: true }).get(
        `filters/${this.entity}/management-companies`
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getBuildingComplexesByCompany(company) {
    try {
      const response = await this.request({ auth: true }).get(
        `filters/${this.entity}/building-complexes/${company}`
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getBuildings(parameters) {
    const params = { ...parameters };

    try {
      const response = await this.request({ auth: true }).get(
        `filters/${this.entity}/buildings`,
        { params }
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getRecipients(parameters) {
    const params = { ...parameters };

    try {
      const response = await this.request({ auth: true }).get(
        `filters/${this.entity}/users`,
        { params }
      );

      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  // static async create(data = {}) {
  //   try {
  //     const response = await this.request({ auth: true }).post(
  //       `${this.entity}`,
  //       data
  //     );
  //     return new ResponseWrapper(response, response.data.data);
  //   } catch (error) {
  //     throw new ErrorWrapper(error);
  //   }
  // }
}
