/**
 * Get type
 *
 * @param obj
 * @returns {string}
 */
export const getType = function getType(obj) {
  return Object.prototype.toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase();
};

/**
 * Get environment variable
 *
 * @param key
 * @param fallback
 * @returns {any}
 */
export const env = function env(key, fallback) {
  const value = process.env[key];

  if (typeof value === 'undefined') {
    return fallback;
  }

  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value);
    case 'number':
      return JSON.parse(value);
    default:
      return value;
  }
};
