<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      floating
      app
    >
      <v-list-item two-line>
        <v-progress-linear v-if="!!!currentUser" indeterminate rounded />

        <v-list-item-content v-else>
          <v-list-item-title class="text-h6">{{
            currentUser.username
          }}</v-list-item-title>
          <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item-group color="primary">
          <v-list-item :to="{ name: 'persons' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{
              $t('NAVIGATION.PERSONS')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'inhabitant_changes' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{
              $t('NAVIGATION.INHABITANT_CHANGES')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'building_complexes' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-city-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{
              $t('NAVIGATION.BUILDING_COMPLEXES')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'notifications' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{
              $t('NAVIGATION.NOTIFICATIONS')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'posts' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-bulletin-board</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ $t('NAVIGATION.POSTS') }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="#5F9E42"
      dark
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <span v-if="domain" class="text-h4 error--text">
          [ {{ domain }} ]
        </span>
        <span class="white--text">Immoportal</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <app-language />

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" fab small v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('AUTH.LOGOUT') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-overlay color="white" :value="loading" z-index="1">
          <v-progress-circular
            :size="32"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-overlay>

        <layout-children />
      </v-container>
    </v-main>

    <v-footer color="#5F9E42" app>
      <span class="white--text">&copy; {{ year }}</span>
    </v-footer>
  </div>
</template>

<script>
import AppLanguage from '@/components/AppLanguage';
import LayoutChildren from './LayoutChildren';
import { AuthService } from '@/services/auth.service';
import { mapActions } from 'vuex';

export default {
  name: 'Layout',

  components: {
    LayoutChildren,
    AppLanguage,
  },

  data: () => ({
    drawer: true,
    loading: false,
    domain: '',
    year: new Date().getFullYear(),
  }),

  created() {
    const isProd = this.$config.get('app.isProduction');

    if (!isProd) {
      this.domain = document.location.hostname;
    }
  },

  methods: {
    ...mapActions('auth', ['removeUser', 'removeToken']),
    ...mapActions('localization', ['setLang']),

    async logout() {
      this.loading = true;

      await AuthService.makeLogout()
        .then(() => {
          AuthService.setBearer('');
        })
        .catch(() => {})
        .finally(() => {
          const lang = this.$config.get('app.i18nLocale');

          this.loading = false;
          this.removeToken();
          this.removeUser();

          this.setLang({ lang });
          this.$vuetify.lang.current = lang;
          this.$i18n.locale = lang;
          this.$router.push({ name: 'login' });
        });
    },
  },
};
</script>
