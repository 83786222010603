import { BuildingComplexesService } from '@/services/building-complexes.service';
import { InhabitantChangesService } from '@/services/inhabitant-changes.service';

export default {
  retrieveBuildingComplex: (_, buildingComplex) => {
    return BuildingComplexesService.getById(buildingComplex);
  },

  getInhabitantChanges(_, { params } = {}) {
    return InhabitantChangesService.getList(params);
  },

  getBuildingComplexesList() {
    return BuildingComplexesService.getSimplifiedList();
  },
};
