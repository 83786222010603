import Vue from 'vue';
import Vuex from 'vuex';
import createCache from 'vuex-cache';

import config from '@/config';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  strict: config.get('app.environment') === 'development',

  plugins: [
    createCache({ timeout: 1000 * 60 * 10 }), // 10 min
  ],
});
