import notFoundPage from '@/views/NotFound.vue';
import LayoutChildren from '@/views/LayoutChildren.vue';
import Layout from '@/views/Layout.vue';
import Login from '@/modules/auth/views/Login.vue';

import persons from '@/modules/persons/router';
import notifications from '@/modules/notifications/router';
import building_complexes from '@/modules/building_complexes/router';
import inhabitant_changes from '@/modules/inhabitant_changes/router';
import posts from '@/modules/posts/router';

export const routes = [
  {
    path: '/',
    component: Layout,
    meta: { isAuth: true },

    children: [
      {
        path: '',
        redirect: { name: 'persons' },
      },

      ...persons,
      ...building_complexes,
      ...notifications,
      ...inhabitant_changes,
      ...posts,
    ],
  },

  {
    path: '/auth',
    component: LayoutChildren,

    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
      },
    ],
  },

  {
    path: '*',
    component: notFoundPage,
  },
];
