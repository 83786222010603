import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from '@/services/util';

export class InhabitantChangesService extends BaseService {
  static get entity() {
    return 'inhabitant-changes';
  }

  static async getStatuses() {
    try {
      const response = await this.request({ auth: true, base_url: true }).get(
        `${this.entity}/statuses`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getById(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${this.entity}/${id}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async remove(id) {
    try {
      const response = await this.request({ auth: true }).delete(
        `${this.entity}/${id}/cancel`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async checkout(data) {
    try {
      const response = await this.request({ auth: true }).post(
        `${this.entity}/check-out`,
        data
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateCheckout(id, data) {
    try {
      const response = await this.request({ auth: true }).patch(
        `${this.entity}/${id}/check-out`,
        data
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
