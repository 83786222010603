import LayoutChildren from '@/views/LayoutChildren.vue';

const routes = [
  {
    path: '/notifications',
    component: LayoutChildren,

    children: [
      {
        path: '',
        name: 'notifications',
        component: () => import('../views/NotificationsPage.vue'),
      },
      {
        path: 'create',
        name: 'notifications.create',
        component: () => import('../views/NotificationCreatePage.vue'),
      },
      {
        path: ':id',
        name: 'notifications.show',
        component: () => import('../views/NotificationShowPage.vue'),
        props: (route) => ({
          id: Number(route.params.id),
        }),
      },
      {
        path: ':id/edit',
        name: 'notifications.edit',
        component: () => import('../views/NotificationEditPage.vue'),
        props: (route) => ({
          id: Number(route.params.id),
        }),
      },
    ],
  },
];

export default routes;
