<template>
  <v-main>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12" :loading="loading">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>{{ $t('AUTH.LOGIN_FORM') }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-form v-model="valid" @submit.prevent="login">
                <v-text-field
                  v-model="form.login"
                  :label="`${$t('AUTH.EMAIL')} *`"
                  prepend-icon="mdi-mail"
                  type="text"
                  autofocus
                  :rules="rules.login"
                  :error-messages="errorsWithoutForm"
                  @input="clearLoginErrors"
                />

                <v-text-field
                  v-model="form.password"
                  :label="`${$t('AUTH.PASSWORD')} *`"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="rules.password"
                  :error-messages="errorsWithoutForm"
                  @input="clearLoginErrors"
                />

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    type="submit"
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                    >{{ $t('AUTH.LOGIN') }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { notEmpty, isEmail } from '@/rules';
import { AuthService } from '@/services/auth.service';
import errors from '@/mixins/errors';

export default {
  name: 'Login',

  mixins: [errors],

  data: () => ({
    valid: false,
    loading: false,

    form: {
      login: '',
      password: '',
    },

    rules: {
      login: [notEmpty, isEmail],
      password: [notEmpty],
    },
  }),

  computed: {
    ...mapGetters('auth', ['HAS_TOKEN']),

    errorsWithoutForm() {
      return ['login', 'email', 'username']
        .reduce((acc, i) => acc.concat(this.errors.get(i)), [])
        .filter((i) => i);
    },
  },

  created() {
    if (this.HAS_TOKEN) {
      this.redirect();
    }
  },

  methods: {
    ...mapActions('auth', ['setToken', 'setUser']),

    login() {
      this.loading = true;

      AuthService.makeLogin(this.form)
        .then(({ data }) => {
          this.setToken({ token: data.api_token });
          this.setUser({ user: data.user });
          AuthService.setBearer(data.api_token);
          this.redirect();
        })
        .catch((response) => {
          this.handleErrorsByResponse(response);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    redirect() {
      this.$router.push('/');
    },

    clearLoginErrors() {
      this.clearErrorByName('login');
      this.clearErrorByName('email');
      this.clearErrorByName('username');
    },
  },
};
</script>
